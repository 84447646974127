import { backendAPI, backendAPISubmitKyc } from "utils/httpAPI.util";
import { API_PATHS } from "commons/consts";
import { getAccessToken } from "utils";
import {
  updateCompanyInfo,
  getBankDetails,
  uploadDocuments,
  updateCompanyAddresses
} from "./company.service";
import {
  getCompanyInfo,
  createCompanyAddresses,
  deleteCompanyAddresses,
  getCompanyAddresses,
  submitBankDetails,
  getDocuments,
  deleteDocuments
} from "./company.service";
import {
  createOwnerInfo,
  uploadOwnerDocuments,
  getOwnerInfo,
  deleteOwnerInfo,
  updateOwnerInfo
} from "./owner.service";

export const saveStep1 = async (values) => {
  const { addresses, companyInfo, deletedAddressIds } = values;
  const promises = [];
  promises.push(updateCompanyInfo(companyInfo));
  addresses.forEach((address) => {
    if (Object.values(address).filter((value) => value !== undefined).length > 0) {
      if (address.id !== undefined) {
        promises.push(updateCompanyAddresses(address.id, address));
      } else {
        promises.push(createCompanyAddresses(address));
      }
    }
  });
  deletedAddressIds.forEach((id) => promises.push(deleteCompanyAddresses(id)));
  await Promise.all(promises);
};

export const getStep1 = async () => {
  const [companyInfo, companyAddresses] = await Promise.all([
    getCompanyInfo(),
    getCompanyAddresses()
  ]);

  return {
    ...companyInfo,
    workingDays: companyInfo.workingDays ? companyInfo.workingDays.split(",") : [],
    addresses: companyAddresses.sort((a, b) => b.main - a.main)
  };
};

export const saveStep2 = async ({ ownerInfo, deletedIds }) => {
  if (!ownerInfo.length) {
    return;
  }
  const promises = [];
  for (const owner of ownerInfo) {
    const { documents, ...reparseValue } = owner;
    const { id } = owner;
    let newCreatedOwner = undefined;
    if (id) {
      promises.push(updateOwnerInfo(id, reparseValue));
    } else {
      newCreatedOwner = await createOwnerInfo(reparseValue);
    }
    if (documents) {
      promises.push(uploadOwnerDocuments(id ? id : newCreatedOwner.id, documents));
    }
  }
  deletedIds.forEach((id) => promises.push(deleteOwnerInfo(id)));
  await Promise.all(promises);
};

export const getStep2 = async () => {
  const values = await getOwnerInfo();
  const parsedValues = values.map((owner) => ({
    ...owner
  }));
  return parsedValues;
};

export const saveStep3 = async ({ bankDetails }) => {
  if (!bankDetails.length) {
    return;
  }
  await submitBankDetails(bankDetails);
};

export const getStep3 = async () => {
  const values = await getBankDetails();
  return values;
};

export const saveStep4 = async (payload) => {
  let promises = [];
  const { documents } = payload;
  if (documents) {
    Object.values(documents).forEach((doc) => {
      promises.push(uploadDocuments(Object.values(doc)));
    });
  }
  await Promise.all(promises);
};

export const getStep4 = async () => {
  const fileList = await getDocuments();
  return fileList;
};

export const deleteDocument = async (docId) => {
  await deleteDocuments(docId);
};

export const submitKYC = async () => {
  const accessToken = await getAccessToken();
  await backendAPISubmitKyc.get(accessToken)(API_PATHS.KYC_SUBMISSION);
};

export const getProductCaterogy = async () => {
  const accessToken = await getAccessToken();
  const rs = await backendAPI.get(accessToken)(API_PATHS.GET_PRODUCT_CATEGORIES);
  return rs;
};

export const getProductType = async (cateId) => {
  const accessToken = await getAccessToken();
  const rs = await backendAPI.get(accessToken)(`${API_PATHS.GET_PRODUCT_TYPE}/${cateId}`);
  return rs;
};

export const getProductName = async (typeId) => {
  const accessToken = await getAccessToken();
  const rs = await backendAPI.get(accessToken)(`${API_PATHS.GET_PRODUCT_NAME}/${typeId}`);
  return rs;
};

export const getSupportingDoc = async (productId, traderType = "ALL") => {
  const accessToken = await getAccessToken();
  const rs = await backendAPI.get(accessToken)(
    `${API_PATHS.GET_PRODUCT_SUPPORTING_DOC}/${productId}`
  );
  return rs;
};

export const addNewProduct = async (data) => {
  const accessToken = await getAccessToken();
  const product = await backendAPI.post(accessToken)(API_PATHS.ADD_NEW_PRODUCT, data);
  return product;
};

export const removeNewProduct = async (id) => {
  const accessToken = await getAccessToken();
  const product = await backendAPI.delete(accessToken)(`${API_PATHS.ADD_NEW_PRODUCT}/${id}`);
  return product;
};

export const updateBusinessDetail = async (values) => {
  const accessToken = await getAccessToken();
  const rs = await backendAPI.post(accessToken)(API_PATHS.BUSINESS_DETAIL, values);
  return rs;
};

export const getBusinessDetail = async () => {
  const accessToken = await getAccessToken();
  const rs = await backendAPI.get(accessToken)(API_PATHS.BUSINESS_DETAIL);
  return rs;
};

export const removeProduct = async (id) => {
  if (!id) return;
  const accessToken = await getAccessToken();
  const rs = await backendAPI.delete(accessToken)(`${API_PATHS.BUSINESS_DETAIL}/${id}`);
  return rs;
};

export const getProfileBusinessDetail = async () => {
  const accessToken = await getAccessToken();
  const rs = await backendAPI.get(accessToken)(API_PATHS.PROFILE_BUSINESS_DETAIL);
  return rs;
};
