import { API_PATHS } from "commons/consts";
import moment from "moment-timezone";
import { getAccessToken, removeAuthCredential, setAuthCredential } from "utils";
import { backendAPI } from "utils/httpAPI.util";

export const login = async (values) => {
  const result = await backendAPI.post()(API_PATHS.LOGIN, { ...values, rememberMe: undefined });
  const { access_token } = result;
  const { rememberMe } = values;

  const userCredentials = {
    accessToken: access_token,
    rememberMe: rememberMe,
    createdDate: moment()
  };

  await setAuthCredential(userCredentials);
  return true;
};

export const register = async (values) => {
  const accessToken = await getAccessToken();
  return await backendAPI.post(accessToken)(API_PATHS.REGISTER, values);
};

export const getCurrentAccount = async () => {
  const accessToken = await getAccessToken();
  if (accessToken) {
    let userInfo = await backendAPI.get(accessToken)(API_PATHS.GET_CURRENT_USER);
    return { ...userInfo, createDate: undefined };
  }
  return undefined;
};

export const suggestNewUsername = async (username, companyName) => {
  const newUsername = await backendAPI.get()(API_PATHS.SUGGEST_USERNAME, { username, companyName });
  return new Promise((r) => r(newUsername));
};

export const logout = async () => {
  await removeAuthCredential();
};

export const sendResetPwEmail = (email) => {
  backendAPI.post()(API_PATHS.SEND_RESET_PW_LINK, null, {
    email
  });
};

export const verifyResetPasswordToken = async (token) => {
  await backendAPI.get()(API_PATHS.CHECK_RESET_PW_TOKEN, { token });
  return true;
};

export const verifyConfirmEmailToken = async (token) => {
  try {
    var res = await backendAPI.get()(API_PATHS.CHECK_EMAIL_CONFIRM_TOKEN, { token });
  } catch (errorObject) {
    throw errorObject;
  }
  return res;
};

export const resendActivationEmail = async (username) => {
  const res = await backendAPI.get()(API_PATHS.RESEND_ACTIVATION_EMAIL, { username });
  return res;
};

export const resetPassword = async (values) => {
  await backendAPI.post()(API_PATHS.RESET_PW, values);
  return true;
};

export const changePassword = async (currentPassword, newPassword) => {
  const accessToken = await getAccessToken();
  await backendAPI.post(accessToken)(API_PATHS.CHANGE_PASSWORD, {
    currentPassword,
    newPassword
  });
  return true;
};

export const sendUnlockAccount = async (values) => {
  await backendAPI.get()(API_PATHS.UNBLOCK_USER, values);
  return true;
};

export const verifyUnlockUser = async (username, token) => {
  return await backendAPI.post()(`${API_PATHS.UNBLOCK_USER}/${username}`, null, token);
};

export const check2FA = () => {
  return false;
};

export const checkRememberComputer = () => {
  return false;
};
